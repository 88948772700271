@font-face
  font-family: 'tt-runs'
  font-style: normal
  font-weight: 600
  font-display: swap
  src: url(/assets/fonts/tt-runs/tt-runs-600.woff2) format('woff2')
@font-face
  font-family: 'inter'
  font-style: normal
  font-weight: 400
  font-display: swap
  src: url(/assets/fonts/inter/inter-400.woff2) format('woff2')
@font-face
  font-family: 'inter'
  font-style: normal
  font-weight: 500
  font-display: swap
  src: url(/assets/fonts/inter/inter-500.woff2) format('woff2')
@font-face
  font-family: 'inter'
  font-style: normal
  font-weight: 700
  font-display: swap
  src: url(/assets/fonts/inter/inter-700.woff2) format('woff2')
