@import 'fonts'
@import 'variables'

*
    margin: 0
    padding: 0
body
    color: #080808
    font-family: inter
    font-weight: 400
    font-size: 16px
    line-height: 24px
ul
    list-style: none
a
    text-decoration: none
    color: inherit
img
    max-width: 100%
h1
    font-family: tt-runs
    font-size: 48px
    font-weight: 600
    margin-bottom: 40px
    line-height: 52px

h2
    font-family: tt-runs
    font-size: 48px
    font-weight: 600
    margin-bottom: 24px
    line-height: 52px
h3
    font-family: tt-runs
main:not(.homepage)
    max-width: 1280px
    margin: 0 auto 0
    box-sizing: border-box
    position: relative
    z-index: 0

.btn-black,
a.btn-black
    background: #080808
    border-radius: 16px
    height: 56px
    color: #fff
    font-size: 16px
    padding: 0 24px
    border: none
a.btn-black
    display: inline-block
    line-height: 56px
    width: 100%
    margin: 0 auto
    box-sizing: border-box
.back-link
    cursor: pointer
    width: 56px
    height: 56px
    border-radius: 16px
    text-align: center
    display: inline-block
    background: rgba(8, 8, 8, 0.05)
    span
        background: transparent $sprite_url no-repeat 19px -249px
        display: inline-block
        vertical-align: top
        width: 56px
        height: 56px
.candie-banner
    background: #dcdcdc
    position: fixed
    left: 20px
    bottom: 20px
    max-width: 300px
    padding: 20px
    border-radius: 20px
    box-shadow: #000 0px 0px 16px -7px
    z-index: 10
    button
      background: #000
      margin: 10px auto 0
      height: 34px
      display: block
      padding: 0 20px
      color: #fff
      border-radius: 16px
      border: none
      cursor: pointer
    p
      color: #515151
      font-size: 12px
    a
      color: #000
      font-weight: bold
      text-decoration: underline






header
    position: relative
    z-index: 1
    display: flex
    align-items: center
    justify-content: space-between
    align-items: center
    padding: 28px 72px
    max-width: 1280px
    margin: 0 auto
    box-sizing: border-box
    a.logo
        line-height: 0
    .links
        white-space: nowrap
.top-container
    .main-banner-content
        max-width: 1280px
        margin: 0 auto 0
        box-sizing: border-box
.header-link
    display: inline-block
    border-radius: 100px
    background: rgba(255, 255, 255, 0.15)
    box-shadow: 0.5px 0.5px 1px 0px rgba(255, 255, 255, 0.09) inset
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.1)
    min-width: 40px
    height: 40px
    text-align: center
    line-height: 48px
    margin-left: 12px
    &.blog-link
        padding: 0 16px
        img
            margin-right: 5px
    img
        vertical-align: text-bottom
    span
        line-height: 40px
        display: inline-block
        vertical-align: top






.footer-links
    max-width: 1280px
    margin: 0 auto 0
    box-sizing: border-box
    p, a
        color: #fff
        font-size: 12px
        letter-spacing: -0.24px
    .copyright,
    .for-customer
        opacity: 0.5
    ul
        display: grid
        align-items: center
        grid-template-columns: 1fr 3fr 1fr
        text-align: center
    .for-customer
        p
            display: inline-block
            vertical-align: top
            margin: 0 12px
    .by-aia
        svg
            position: relative
            top: 4px
.field, .form-page
    input, button
        width: 100%
        border-radius: 16px
        height: 56px
        border: 1px solid #080808
        font-size: 16px
        letter-spacing: 0.38px
        outline: none
        transition: border-color 0.3s ease-in-out
        &:disabled
          opacity: 0.8
    input
        background: rgba(8, 8, 8, 0.05)
        padding: 0 16px
        box-sizing: border-box
        &.error
            border-color: #E12020
    button
        cursor: pointer
        margin-bottom: 24px
        transition: opacity .3s ease
        &:hover
            opacity: 0.8
    .dark-btn
        background: #080808
        color: #fff
    .light-btn
        height: 40px
        border: none
        background: #fff
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10)
        padding: 0 16px
        width: 90%
        color: rgba(8, 8, 8, 0.3)
        white-space: nowrap
    .input-box
        position: relative
        z-index: 0
        box-sizing: border-box
        min-height: 82px
        input[type=number]::-webkit-outer-spin-button,
        input[type=number]::-webkit-inner-spin-button
            -webkit-appearance: none
            margin: 0
        input[type=number]
            -moz-appearance: textfield
.form-page
    height: 100vh
    display: flex
    align-items: center
    justify-content: space-between
    flex-direction: column
    padding: 40px 0
    h2
        font-size: 30px
        font-family: inter
        font-weight: 700
        line-height: 30px
        letter-spacing: -0.64px
        margin-bottom: 8px
    header
        text-align: center
        position: relative
        z-index: 0
        width: 100%
        display: block
        padding: 0
    .back-wrapper
        position: absolute
        left: 72px
        top: -7px
    p
        line-height: 22px
        color: rgba(8, 8, 8, 0.5)
        letter-spacing: -0.32px
    a
        color:#080808
    .content
        text-align: center
        width: 100%
    form
        text-align: center
    .login-form
        max-width: 343px
        margin: 0 auto
    .message
        font-size: 12px
        opacity: 0
        visibility: hidden
        transition: all 0.3s ease-in-out
        text-align: left
        min-height: 22px
        line-height: 14px
        padding: 2px 0 6px
        box-sizing: border-box
        &.shown
            opacity: 1
            visibility: visible
        &.error
            color: #E12020
    .legend
        margin-bottom: 24px
    .label-agree
        p
            padding-left: 32px
            text-align: left

    input#agree
        width: 20px
        height: 20px
        display: inline-block
        float: left
        padding: 0
        box-sizing: border-box
        -webkit-appearance: none
        -moz-appearance: none
        appearance: none
        background-color: transparent
        transition: background-color 0.3s ease-in-out
        border-radius: 6px
        &:checked
            background: transparent $sprite_url no-repeat -400px 4px!important
.account-page
    .btn-white, .btn-black
        cursor: pointer
        transition: opacity .3s ease
        &:hover
            opacity: .8
.account
    max-width: 1280px
    margin: 0 auto
    padding: 40px 0
    display: flex
    flex-direction: column
    min-height: 100vh
    justify-content: space-between
    h2
        text-align: center
        font-family: inter
        font-size: 30px
        line-height: 32px
    header
        position: relative
        z-index: 0
        text-align: center
        display: block
        margin: 0
        padding: 0
    .footer-links p,
    .footer-links a
        color: #080808
    .logout-wrapper
        position: absolute
        right: 70px
        top: -8px
    .logout-link
        background: #fff
        border-radius: 16px
        padding: 16px 24px
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10)
        transition: opacity 0.3s ease-in-out
        display: inline-block
        &:hover
            opacity: 0.8
        &::before
            content: " "
            display: inline-block
            vertical-align: middle
            width: 20px
            height: 20px
            background: transparent $sprite_url no-repeat -49px -319px
            margin-right: 6px
    .download-to-phone
        color: #fff
        background: #080808
        border-radius: 24px
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%
        padding: 4px
        font-size: 16px
        font-weight: 400
        height: 90px
        box-sizing: border-box
        padding-left: 20px
        transition: opacity 0.3s ease-in-out
        max-width: 330px
        margin: 0 auto 36px
        &:hover
            opacity: 0.8
        .phone-icon
            background: transparent $sprite_url no-repeat -49px -2px
            display: inline-block
            vertical-align: middle
            width: 22px
            height: 20px
            margin-right: -12px
        &.android .phone-icon
            background-position: -116px -37px
        .q-icon
            border-radius: 20px
            max-width: 82px
    .to-subscription
        &::after
            content: " "
            width: 20px
            height: 20px
            display: inline-block
            vertical-align: middle
            background: transparent $sprite_url no-repeat 0 -353px
            margin-left: 6px
    .subscription-banner
        max-width: 343px
        margin: 0 auto
    .subscription-wrapper
        border-radius: 24px
        padding: 16px
        box-sizing: border-box
        width: 100%
        color: #fff
        background: transparent url('/assets/images/subscribe.jpg') no-repeat center center
        a
            color: #fff
        p
            font-size: 16px
            line-height: 16px
            letter-spacing: -0.32px
            &:first-child
                margin-bottom: 18px
    .subscription-name
        font-weight: 600

    .bottom-links
        text-align: center
        a
            color: rgba(8, 8, 8, 0.30)
            font-size: 14px
            line-height: 14px
            letter-spacing: -0.28px
            display: inline-block
            vertical-align: top
    .actions-wrapper
        .continue-whithout-sbp
            display: none
    .bottom-container
        .logo
            display: none!important
.options-list
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10)
    border-radius: 24px
    padding: 24px
    max-width: 423px
    margin: 0 auto 40px
    span.include,
    span.not-include
        width: 20px
        height: 20px
        display: inline-block
        vertical-align: middle
    span.include
        background: transparent $sprite_url no-repeat -200px -260px
    span.not-include
        background: transparent $sprite_url no-repeat -250px -260px
    tr:first-child
        td
            border-bottom: 0
            padding: 0
            p
                font-size: 12px
                color: #080808
                line-height: 18px
                &.dark-label
                    color: #fff
                    background: #080808
                    border-radius: 40px
                    padding: 0 8px
        td:nth-child(even)
            padding-right: 12px
    tr:last-child
        td
            border-bottom: 0
            padding-bottom: 0
    tr
        vertical-align: top
    td
        text-align: center
        border-bottom: 1px solid rgba(8, 8, 8, 0.08)
        padding: 12px 0
        &:first-child
            text-align: left
            position: relative
            z-index: 0
            padding-left: 28px
        &:nth-child(even)
            padding-right: 12px
        
    .service-name
        font-size: 14px
        color: #080808
        font-weight: 600
        line-height: 22px
    .service-options
        font-size: 12px
        font-weight: 400
        line-height: 18px
    td.with-icon
        position: relative
        z-index: 0
        padding-left: 28px
        &::before
            content: " "
            width: 20px
            height: 20px
            background: transparent $sprite_url no-repeat 
            position: absolute
            top: 12px
            left: 0
        &.cycle-track
            &::before
                background-position: -117px 1px
        &.statistic
            &::before
                background-position: -157px 1px
        &.food
            &::before
                background-position: -197px 1px
        &.activity
            &::before
                background-position: -237px 1px
        &.moon
            &::before
                background-position: -277px -1px
        &.blog
            &::before
                background-position: -317px 1px
    
.subscription-links 
    max-width: 517px
    margin: 0 auto
    p 
        font-size: 14px
        text-align: center
        color: rgba(8, 8, 8, 0.50)
        margin-bottom: 24px

.calendar-controls
    position: absolute
    top: 48px
    right: 70px
    a
        margin-left: 24px
    .icon
        background: transparent $sprite_url no-repeat
        width: 20px
        height: 20px
        margin-right: 6px
        display: inline-block
        vertical-align: middle
        &.clear
            background-position:  -50px -262px
        &.today
            background-position:  -100px -262px


.calendar-wrapper
    .control
        max-width: 400px
        margin: 0 auto
    dp-day-calendar
        width: 100%
        max-width: 343px
        .dp-calendar-wrapper
            border: none
        .dp-weekdays
            display: flex
            align-items: center
            justify-content: space-between
            margin-bottom: 16px
        .dp-calendar-weekday
            border: none
            color: rgba(8, 8, 8, 0.50)
            font-size: 14px
            text-transform: capitalize
        .dp-calendar-week 
            display: flex
            align-items: center
            justify-content: space-between
        .dp-prev-month,
        .dp-next-month
            opacity: 0
        .dp-calendar-day
            border: none
            border-radius: 12px
            position: relative
            z-index: 0
            padding: 7px 12px
            width: auto
            height: auto
            background: rgba(8, 8, 8, 0.05)
            color: #080808
            &::after
                background: #DCDCDC
                border-radius: 50%
                width: 12px
                height: 12px
                content: " "
                display: block
                margin: 4px auto 0
            &.dp-selected
                background: rgba(8, 8, 8, 0.05)
                color: #080808
                &::after
                    background: transparent $sprite_url no-repeat -150px -260px
            &:disabled
              opacity: 0.5

    dp-calendar-nav
        .dp-calendar-nav-container
            height: auto
            border: none
        .dp-nav-header
            position: static
            transform: none
            display: block
            margin-bottom: 24px
            span
                font-size: 24px
                font-weight: 700
                letter-spacing: -0.48px
                text-transform: capitalize
        .dp-nav-header-btn
            border: none
            background: transparent
        .dp-nav-btns-container
            left: 0
            right: 0
            width: 100%
            transform: translateY(-29%)
        .dp-calendar-nav-container-left
            float: left
        .dp-calendar-nav-left
            &::before
                transform: none
        .dp-calendar-nav-container-right
            float: right
        .dp-calendar-nav-right
            &::before
                transform: rotate(180deg)
        .dp-calendar-nav-left, 
        .dp-calendar-nav-right
            position: static
            border: none
            background: transparent
            height: auto
            &::before
                border: none
                width: 20px
                height: 20px
                background: transparent $sprite_url no-repeat 2px -266px
main.legal-page
    .main-wrapper
        max-width: 1280px
        margin: 0 auto 0
        box-sizing: border-box
        position: relative
        z-index: 0
    .content
        a
            text-decoration: underline
            font-weight: 700
        ol
            > li
                margin-bottom: 12px
        .table-container
            width: 100%
            overflow: auto
        .bordered-table
            border-collapse: collapse
            width: 100%
            box-sizing: border-box
            margin-bottom: 60px
            td
                border: 1px solid #080808
                padding: 16px
                box-sizing: border-box
    ol
        list-style: none
        margin-bottom: 40px
    h3
        margin-bottom: 16px
        text-align: center
    .legal-heading
        text-align: center
        margin: 32px 0
        em
            display: inline-block
            padding: 10px 0
    ul.style-disc
        list-style: disc inside
    .not-found-content
        min-height: 100vh
        display: flex
        flex-direction: column
        justify-content: space-between
        padding-bottom: 60px
        .content
            text-align: center
            .btn-black
                text-decoration: none
                max-width: 343px
                font-weight: 400
        h2
            margin-bottom: 60px
.legal-page,
.blog-page
    .footer-links
        p, a
            font-size: 12px
            letter-spacing: -0.24px
            color: #080808
        .copyright,
        .for-customer,
        .by-aia
            a
                text-decoration: none
                font-weight: normal
        ul
            display: grid
            align-items: center
            grid-template-columns: 1fr 3fr 1fr
            text-align: center
        .for-customer
            p
                vertical-align: top
                margin: 0 12px
        .by-aia
            svg
                position: relative
                top: 4px
    .content
        padding: 0 72px 60px
        max-width: 1280px
        margin: 0 auto 0
        box-sizing: border-box
.response-preloader
    background: transparent url(/assets/images/logo_small.svg) no-repeat 100% center
    width: 40px
    height: 40px
    margin: 0 auto 40px
    animation: spin 2s linear infinite
    -webkit-animation: spin 4s linear infinite

.blog-page
    h1
        font-size: 30px
        line-height: 30px
        margin-bottom: 10px
        font-family: inter
        letter-spacing: -0.02em
    .content
        max-width: 1088px
        padding-top: 5px
    .blog-container
        min-height: calc(100vh - 200px)
    .post-tags
        margin-bottom: 12px
        li
            display: inline-block
        a
            background: rgba(8, 8, 8, 0.05)
            color: rgba(8, 8, 8, 0.5)
            border-radius: 40px
            padding: 0 8px
            margin-right: 8px
            display: inline-block
            font-size: 12px
            height: 20px
            line-height: 20px
    .created-at
        margin-bottom: 24px
        color: rgba(8, 8, 8, 0.5)
    .post-image
        border-bottom: 1px solid #dcdcdc
        padding-bottom: 42px
        margin-bottom: 48px
        img
            width: 100%
            border-radius: 32px
    .author-info
        display: flex
        align-items: center
        margin-bottom: 24px
    .author-image
        width: 56px
        height: 56px
        border-radius: 100%
        margin-right: 16px
    .tag-list
        width: 100%
        overflow: auto
        margin-bottom: 32px
        white-space: nowrap
        li
            display: inline-block
        a
            display: inline-block
            padding: 5px
            transition: .3s background ease, .3s color ease
            background: #0808080D
            color: #080808
            border-radius: 16px
            margin-right: 8px
            padding: 0 16px
            height: 40px
            line-height: 40px
            &.active
                background: #080808
                color: #FFFFFF
    .blog-content
        h1
            margin-bottom: 22px
    .post-content
        h2
            font-size: 22px
            font-weight: 600
            font-family: 'inter'
            letter-spacing: -0.02em
            line-height: 22px
            margin-bottom: 10px
        p
            line-height: 22px
            margin-bottom: 30px
        img
            border-radius: 32px
            width: 100%
            margin-bottom: 32px

    .post-list
      display: grid
      grid-template-columns: 1fr 1fr 1fr
      gap: 24px
      transition: .3s ease opacity
      margin-bottom: 60px
      > li
          border-radius: 24px
          padding: 4px
          background: #FFFFFF
          box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1)
          img
              border-radius: 24px
      .post-info
          display: block
          padding: 12px
      .post-title
          font-weight: 500
          line-height: 22px
          margin-bottom: 8px
          overflow: hidden
          text-overflow: ellipsis
          display: -webkit-box
          -webkit-line-clamp: 2
          -webkit-box-orient: vertical
    .readtime
        height: 20px
        line-height: 20px
        font-size: 12px
        font-weight: 500
        background: rgba(8, 8, 8, 0.05)
        display: inline-block
        padding: 0 8px
        border-radius: 40px
    .loading .post-list
        opacity: .5
@keyframes spin
  100%
    transform: rotate(360deg)
    -webkit-transform: rotate(360deg)

@media only screen and (max-width: 1024px)
    .footer-links
        ul
            align-items: end
        .for-customer
            p
                display: block
@media only screen and (min-width: 768px)
    .loading
        .input-box
            input[type=text],
            input[type=email]
                border-color: #DCDCDC
                background: rgba(8, 8, 8, 0.05) url(/assets/images/preloader.svg) no-repeat 100% center
    .account
        .logout-wrapper
            &.mobile
                display: none
        &.only-back
            .back-wrapper
                position: absolute
                left: 70px
                top: -5px
    .calendar-section
        .cl-heading-mobile
            display: none
@media only screen and (max-width: 768px)
    .candie-banner
        width: 100%
        bottom: 0
        left: 0
        box-sizing: border-box
        max-width: none
        padding: 12px 12px 5px
        border-radius: 0
        button
          margin: 5px auto
    .loading
        .control
            button
                background: #080808 url(/assets/images/preloader_white.svg) no-repeat center center
                span
                    visibility: hidden
                    opacity: 0
    .only-back,
    .only-back.account
        header
            margin-bottom: 0
            .logo
                display: none
            .back-link
                background: transparent
        .subscriptions-plans
            padding-top: 0
    .footer-links
        ul
            grid-template-columns: 1fr 1fr
        .for-customer
            order: -1
            grid-column: span 2
            text-align: left
            p
                margin: 0
        .copyright
            text-align: left
        .by-aia
            text-align: right
    .form-page
        padding: 0 16px
        justify-content: space-between
        header
            .logo
                display: none
        .heading
            text-align: left
            margin-bottom: 16px!important
        .legend
            text-align: left
        .login-form
            max-width: 100%
            h2
                text-align: left
        .bottom-wrapper
            padding-bottom: 16px
            text-align: center
        .content
            text-align: left
            position: relative
            z-index: 0
        .back-wrapper
            position: static
            text-align: left
            .back-link
                background: transparent
                margin-left: -21px
        .label-agree
            p
                font-size: 12px
                margin-bottom: 24px
    header
        margin-bottom: 16px
        text-align: left
        .back-wrapper
            position: static
            .back-link
                background: transparent
                margin-left: -21px
    .account
        padding: 16px 16px 150px
        display: block
        position: relative
        z-index: 0
        header
            text-align: left
            margin-bottom: 24px

        h2
            text-align: left
        .logo img
            max-width: 150px
        .logout-wrapper 
            display: none
            &.mobile
                display: block
                position: static
                width: 100%
                text-align: center
            .logout-link
                display: inline-block
                vertical-align: top
                width: 100%
                margin: 0
                box-sizing: border-box
        .subscription-banner
            margin-bottom: 24px
            max-width: 100%
        .download-to-phone
            max-width: 100%
            height: 56px
            border-radius: 16px
            margin-bottom: 24px
            justify-content: center
            .q-icon
                display: none
            .phone-icon
                background-position: -54px -0
                margin-right: 2px
            &.android .phone-icon
                background-position: -120px -38px
        .bottom-links
            &.bottom-position
                position: absolute
                bottom: 16px
                left: 0
                right: 0
                padding: 0 16px
    .options-list
        max-width: 100%
    .subscription-links
        p
            margin-bottom: 16px
    .calendar-section
        .heading
            text-align: center
            h2
                font-size: 22px
                margin-bottom: 8px
                letter-spacing: -0.32px
                line-height: 22px
                transition: color 0.3s ease-in-out 
        &.calendar-error
            .heading
                h2
                    color: #E12020
    main.legal-page,
    main.blog-page
        header
            padding: 28px 16px
        .legal-heading
            h2
                font-size: 24px
                line-height: 36px
        .content
            padding: 0 16px 60px
        .footer-links
            ul
                grid-template-columns: 1fr 1fr
            .for-customer
                order: -1
                grid-column: span 2
                text-align: left
                p
                    margin: 0
            .copyright
                text-align: left
            .by-aia
                text-align: right
        .not-found-content
            padding: 0 10px 20px
            h2
                font-size: 20px
                line-height: 24px
                margin-bottom: 24px
    main.blog-page
        header
            margin-bottom: 0
            padding-bottom: 24px
        .post-list
            grid-template-columns: 1fr
        .tag-list
            margin-bottom: 8px
            padding-bottom: 8px
        .blog-content
            h1
                margin-bottom: 16px
            img
                border-radius: 20px
                margin-bottom: 20px
        .post-image
            padding-bottom: 26px
            margin-bottom: 26px
            img
                border-radius: 20px

@media only screen and (max-width: 468px)
    .calendar-wrapper
        dp-day-calendar
            max-width: 100%
.subscriptions-plans
    max-width: 664px
    padding: 40px 0
    margin-left: auto
    margin-right: auto
    &.loading
      button
        opacity: 0
      .subscriptions-list
        opacity: .8
    h2
        text-align: center
        margin-bottom: 36px
    button
        width: 100%
        max-width: 343px
        margin: 0 auto
        margin-bottom: 24px
    .message
        font-size: 12px
        line-height: 16px
        margin: 5px 0
        opacity: 0
        visibility: hidden
        transition: all 0.3s ease-in-out
        min-height: 22px
        &.shown
            opacity: 1
            visibility: visible
        &.error
            color: #E12020
    .plan-form .message
        text-align: center
        margin: -30px 0 6px
    .options-list
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10)
        border-radius: 24px
        padding: 24px
        max-width: 423px
        margin: 0 auto 40px
        span.include,
        span.not-include
            width: 20px
            height: 20px
            display: inline-block
            vertical-align: middle
        span.include
            background: transparent $sprite_url no-repeat -200px -260px
        span.not-include
            background: transparent $sprite_url no-repeat -250px -260px
        tr:first-child
            td
                border-bottom: 0
                padding: 0
                p
                    font-size: 12px
                    color: #080808
                    line-height: 18px
                    &.dark-label
                        color: #fff
                        background: #080808
                        border-radius: 40px
                        padding: 0 8px
            td:nth-child(even)
                padding-right: 12px
        tr:last-child
            td
                border-bottom: 0
                padding-bottom: 0
        tr
            vertical-align: top
        td
            text-align: center
            border-bottom: 1px solid rgba(8, 8, 8, 0.08)
            padding: 12px 0
            &:first-child
                text-align: left
                position: relative
                z-index: 0
                padding-left: 28px
            &:nth-child(even)
                padding-right: 12px
        .service-name
            font-size: 14px
            color: #080808
            font-weight: 600
            line-height: 22px
        .service-options
            font-size: 12px
            font-weight: 400
            line-height: 18px
        td.with-icon
            position: relative
            z-index: 0
            padding-left: 28px
            &::before
                content: " "
                width: 20px
                height: 20px
                background: transparent $sprite_url no-repeat 
                position: absolute
                top: 12px
                left: 0
            &.cycle-track
                &::before
                    background-position: -117px 1px
            &.statistic
                &::before
                    background-position: -157px 1px
            &.food
                &::before
                    background-position: -197px 1px
            &.activity
                &::before
                    background-position: -237px 1px
            &.moon
                &::before
                    background-position: -277px -1px
            &.blog
                &::before
                    background-position: -317px 1px

.subscriptions-list
    display: flex
    align-items: stretch
    justify-content: center
    gap: 8px
    margin-bottom: 36px
    transition: opacity .3s ease
    li
        display: inline-block
        vertical-align: top
    label
        border: 1px solid transparent
        display: inline-block
        vertical-align: top
        border-radius: 16px
        background: rgba(8, 8, 8, 0.05)
        padding: 16px
        text-align: left
        cursor: pointer
        transition: border-color 0.3s ease-in-out
        position: relative
        z-index: 0
        min-width: 160px
        box-sizing: border-box
        .label-sale,
        .label-trial
            position: absolute
            left: 16px
            top: -8px
            background: #3AB046
            border-radius: 40px
            color: #fff
            font-size: 12px
            text-align: center
            padding: 4px 8px
            height: 20px
            line-height: 12px
            box-sizing: border-box
        p.label-trial
            background: transparent
            white-space: normal
            top: auto
            bottom: -1px
            border-radius: 0
            padding: 0
            left: 0
            right: 0
            span
                background: #3AB046
                border-radius: 16px
                padding: 4px 8px
                box-sizing: border-box
        p
            font-size: 16px
            span
                display: block
                width: 100%
                &.s-period
                    font-weight: 600
                    color: #080808
                &.price
                    color: rgba(8, 8, 8, 0.50)
                    font-size: 14px
            &.s-name
                color: #080808
                margin-bottom: 16px
                white-space: nowrap
        &:hover
            border-color: #080808
    input
        visibility: hidden
        opacity: 0
        width: 0
        height: 0
    input:checked
        + label
            border-color: #080808
.subscribe-info
    padding-top: 60px
    &.loading
        .info-list
            min-height: 100px
        .btn-white, .btn-black
          opacity: 0
    .info-list
        max-width: 343px
        margin: 0 auto 24px
        padding: 16px
        box-sizing: border-box
        background: #fff
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10)
        border-radius: 24px
        font-size: 14px
        li
            margin-bottom: 8px
        li.subscription-name
            font-size: 16px
            line-height: 16px
            margin-bottom: 12px
        li.with-icon
            position: relative
            z-index: 0
            line-height: 18px
            p
                display: inline-block
                vertical-align: top
            &::before
                content: " "
                width: 20px
                height: 20px
                display: inline-block
                vertical-align: middle
                margin-right: 8px
        .included-options
            &.all
                color: #3AB046
                &::before
                    background: transparent $sprite_url no-repeat -198px -259px
            &.limited
                color: #D78335
                &::before
                    background: transparent $sprite_url no-repeat -298px -259px
        li.price
            &::before
                background: transparent $sprite_url no-repeat -347px -259px
        li.expiration
            &::before
                background: transparent $sprite_url no-repeat -397px -259px
    .actions-wrapper
        padding: 0 0 24px
        text-align: center
        max-width: 343px
        margin: 0 auto
        button
            margin-bottom: 8px
            width: 100%
    .card-subscription-actions
        margin: 0 auto 24px
        text-align: center
        .payment-heading
            font-weight: 700
            margin-bottom: 7px
        .card-info
            margin-bottom: 24px
    p.action
        margin-bottom: 16px
    .btn-white
        transition: opacity 0.3s ease-in-out 
        display: inline-block
        width: 100%
        max-width: 343px
        padding: 0 16px
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.10)
        background: #fff
        border-radius: 16px
        height: 56px
        line-height: 56px
        box-sizing: border-box
        font-weight: 500
        &:hover
            opacity: 0.7
        &.cancel-subscription
            color: #E12020
    @media only screen and (max-width: 768px)
        padding-top: 0
        .info-list
            max-width: 100%
        .card-subscription-actions .card-info
            text-align: left
            padding: 20px 0
.subscriptions-plans
    &.loading
        .btn-black, .btn-white
            opacity: 0
    .actions-wrapper
        margin-bottom: 60px
        text-align: center
        a
            text-decoration: underline
            color: #080808
            display: inline-block
            vertical-align: top
            width: 100%
        .sub-info
            font-size: 12px
            line-height: 16px
            color: rgba(8, 8, 8, 0.50)
    .bottom-links
        text-align: center
        li
            display: inline-block
            vertical-align: top
            text-align: center
            padding: 0 12px
        a
            color: rgba(8, 8, 8, 0.30)
            font-size: 14px
            line-height: 14px
            letter-spacing: -0.28px
            display: inline-block
            vertical-align: top
    .bottom-container
        text-align: center
        .logo
            margin-bottom: 24px
            display: inline-block
            vertical-align: top
    .promocode
        h2
          font-size: 26px
          margin-bottom: 20px
        button
            max-width: none
        .include
            position: absolute
            top: 20px
            right: 10px
@media only screen and (max-width: 768px)
    .subscribe-info .actions-wrapper,
    .subscriptions-plans .actions-wrapper
        position: fixed
        background: #fff
        bottom: 0
        left: 0
        right: 0
        padding: 16px
        z-index: 2
        margin: 0
        text-align: center
        max-width: 100%
        box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.1)
        button
            max-width: 100%
            width: 100%
    .subscribe-info
        .btn-white
            max-width: 100%
    .subscriptions-list
        overflow: auto
        padding: 10px 0 34px
        justify-content: left
        margin-bottom: 4px
        label
            white-space: nowrap
    .subscriptions-plans
        h2
            text-align: left
            margin-bottom: 8px
        .actions-wrapper
            margin-bottom: 0
    .bottom-links
        li
            width: 100%
            margin-bottom: 8px
            padding: 0
